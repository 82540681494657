import { fetchWithToken } from "../utils/fetch_with_token";
import { updateProgress } from "../utils/updateProgress";
import { makeElementAppear } from "../utils/appearAndDisappear";
import { makeElementDisappear } from "../utils/appearAndDisappear";
import { colorEyeBlack } from "../utils/colorEyeBlack";

const associationAnswer = () => {
  var win = $('.footer');
  var windowWidth = win.width();

  const boxQuizWithClues = document.querySelector(".box-quiz-with-clues");
  const questionNumber = document.getElementById("question-number");
  const questionNumbers = document.getElementById("question-numbers");
  const progressUnity = (100 / parseInt(questionNumbers.innerText, 10));
  const progressSuccessMain = document.getElementById('progress-success-main');
  const progressSuccessNavbar = document.getElementById('progress-success-navbar');
  const progressFailMain = document.getElementById('progress-fail-main');
  const progressFailNavbar = document.getElementById('progress-fail-navbar');

  const launchBoxPicture = document.querySelector(".launch-box-picture");
  const launchBoxBody = document.querySelector(".launch-box-body");
  const boxQuizQuestion = document.getElementById('box-quiz-question');
  const cluesHelperWrapper = document.getElementById("clues-helper-wrapper");
  const boxClues = document.getElementById('box-clues');
  const beginnerHelper = document.getElementById('beginner-helper');
  const launchBoxAnswerEnvelope = document.getElementById("launch-box-answer-envelope");
  const launchBoxAnswer = document.getElementById("launch-box-answer");
  const btnNextQuestion = document.getElementById('btn-next-question');

  const boxQuizForm = document.getElementById("box-quiz-form");
  const formAnswer = document.getElementById("form-answer");
  const elementId = document.getElementById("element_element_id");
  const quizId = document.getElementById("element_quiz_id");
  const proposedAnswer = document.getElementById("proposed-answer");
  const btnSubmitAnswer = document.getElementById('btn-submit-answer');

  let clueUsed = "";
  const btnFail = document.getElementById('btn-fail');
  const btnClueOne = document.getElementById('btn-clue-one');
  const btnClueTwo = document.getElementById('btn-clue-two');
  const btnClueThree = document.getElementById('btn-clue-three');
  let domageText = " Too bad ! ";
  let notTheGoodAnswer = "<span>It is not the expected answer ! </span>";
  let wellDone = "Well done ! ";
  let seeScore = "See Score ";
  let expectedAnswer = " is the expected answer !"

  const identifyMode = () => {
    if (quizMode < 3) {
      setTimeout(() => btnClueOne.classList.add('sliding-down-one'), 500);
      setTimeout(() => btnFail.classList.add('sliding-down-one'), 500);
    } else if (quizMode === 3) {
      setTimeout(() => btnFail.classList.add('sliding-down-one'), 500);
    }
  }
  identifyMode();
  if (windowWidth > 500) { cluesHelperWrapper.classList.add('transition-all-0-3') };

  const removeClue = (clueText, timer, btn) => {
    boxClues.classList.add('clue-hidden');
    if (clueText.length >= 1) {
      btn.querySelectorAll('.far').forEach((element) => element.style.color = 'rgb(119, 182, 234)');
      setTimeout(() => {
        boxClues.querySelector('span').innerText = `${clueText}`;
        boxClues.classList.remove('clue-hidden');
        if (windowWidth <= 500) {
          $('#clues-helper-wrapper').slideDown();
        } else {
          makeElementAppear(cluesHelperWrapper);
        }
      }, timer);
    } else {
      setTimeout(() => boxClues.querySelector('span').innerText = `${clueText}`, timer);
    }

  }

  const displayingClue = (clueText, btn) => {

    if (windowWidth <= 500) {
      $('#clues-helper-wrapper').slideUp();
    } else {
      makeElementDisappear(cluesHelperWrapper);
    }

    if (boxClues.classList.contains('clue-hidden')) {
      setTimeout(() => {
        btn.querySelectorAll('.far').forEach((element) => element.style.color = 'rgb(119, 182, 234)');
        boxClues.querySelector('span').innerText = clueText;
        boxClues.classList.remove('clue-hidden');
        if (windowWidth <= 500) {
          $('#clues-helper-wrapper').slideDown();
        } else {
          makeElementAppear(cluesHelperWrapper);
        }
      }, 510);
    } else {
      if (boxClues.querySelector('span').innerText === clueText) {
        setTimeout(() => {
          boxClues.classList.add('clue-hidden');
          boxClues.querySelector('span').innerText = "";
          if (windowWidth <= 500) {
            $('#clues-helper-wrapper').slideDown();
          } else {
            makeElementAppear(cluesHelperWrapper);
          }
        }, 510);
      } else {
        removeClue(clueText, 510, btn);
      }
    }
  }

  const displayResult = (data, textToDisplay1, ClassToAdd, color, rescueText, textToDisplay2) => {
    if (windowWidth <= 500) {
        $('.launch-box-body').slideUp();
        makeElementDisappear(boxQuizForm);
        boxClues.classList.add('clue-hidden');
        setTimeout(() => {
          boxClues.querySelector('span').innerText = ""
          if (quizMode === 1) { beginnerHelper.innerHTML = "" };
        }, 350);
        setTimeout(() => {
          makeElementAppear(launchBoxAnswer);
          makeElementAppear(btnNextQuestion);
        }, 250);

        setTimeout(() => {
          launchBoxAnswer.innerHTML = `
            <div id="answer-symbol"><i class="fas fa-lg ${ClassToAdd}" style='color:${color};'></i></div>
            <div>
              <span id='inject-text'>${textToDisplay1}</span>
              <strong><span id='inject-answer' style='color:${color};'>${data.answer}</span></strong>
              <span>${textToDisplay2}</span><span id='rescue-answer'>${rescueText}</span>
            </div>`;
          $('.launch-box-body').slideDown();
        }, 300)

    } else {
        makeElementDisappear(cluesHelperWrapper);
        makeElementDisappear(boxQuizForm);
        boxClues.classList.add('clue-hidden');
        setTimeout(() => {
          boxClues.querySelector('span').innerText = ""
          if (quizMode === 1) { beginnerHelper.innerHTML = "" };
        }, 350);
        setTimeout(() => {
          launchBoxAnswer.innerHTML = `<div id="answer-symbol"><i class="fas fa-lg ${ClassToAdd}" style='color:${color};'></i></div>
          <div><span id='inject-text'>${textToDisplay1}</span><strong><span id='inject-answer' style='color:${color};'>${data.answer}</span></strong><span>${textToDisplay2}</span><span id='rescue-answer'>${rescueText}</span></div>`;
          makeElementAppear(cluesHelperWrapper);
        }, 300)
        setTimeout(() => {
          makeElementAppear(launchBoxAnswer);
          makeElementAppear(btnNextQuestion);
        }, 500);
    }


    btnClueOne.classList.remove('clue-used');
    btnClueTwo.classList.remove('clue-used');
    btnClueThree.classList.remove('clue-used');

    document.querySelectorAll('.clues').forEach((btn) => {
      btn.classList.add('sliding-up');
      setTimeout(() => btn.classList.remove('sliding-down-one'), 510);
      setTimeout(() => btn.classList.remove('sliding-up'), 520);
    });
    btnNextQuestion.classList.add('ready-for-click');

  }

  const setNextQuestion = () => {
    let imgFirst = document.querySelector('.img-first');
    let imgSecond = document.querySelector('.img-second');

    if (windowWidth <= 500) {
        $('.launch-box-body').slideUp();
        makeElementDisappear(launchBoxBody);
        makeElementDisappear(btnNextQuestion);
        makeElementAppear(boxQuizForm);

        launchBoxAnswer.innerHTML = "";
        proposedAnswer.value = "";
        questionNumber.innerText = parseInt(questionNumber.innerText, 10) + 1;
        boxQuizQuestion.innerHTML = `<span>${quizQuestions[parseInt(questionNumber.innerText, 10) - 1].question}</span>`;
        elementId.value = quizQuestions[parseInt(questionNumber.innerText, 10) - 1].id;

        setTimeout(() => {
          launchBoxPicture.removeChild(imgFirst);
          imgSecond.classList.add('img-first');
          imgSecond.classList.remove('img-second');
          launchBoxPicture.classList.add('flipped-down');
        }, 275);

        setTimeout(() => {
          identifyMode()
          makeElementAppear(launchBoxBody);
          $('.launch-box-body').slideDown();
        }, 600);

        setTimeout(() => launchBoxPicture.classList.add('flip-down'), 290);
        setTimeout(() => {
          launchBoxPicture.classList.remove('flip-down');
          launchBoxPicture.classList.remove('flipped-down')
        }, 700);

        setTimeout(() => launchBoxPicture.insertAdjacentHTML('beforeend', `<img height="200" width="250" class="img-second"
          src="https://res.cloudinary.com/dxtybzm3a/image/upload/f_auto,q_70,c_scale,dpr_auto,h_120,r_5,w_150/${quizQuestions[parseInt(questionNumber.innerText, 10)].image}"
          alt="${quizQuestions[parseInt(questionNumber.innerText, 10)].imageAlt}" title="${quizQuestions[parseInt(questionNumber.innerText, 10)].imageAlt}">`), 700);

    } else {
        makeElementDisappear(launchBoxAnswer);
        makeElementDisappear(cluesHelperWrapper);
        makeElementDisappear(btnNextQuestion);

        launchBoxAnswer.innerHTML = "";
        proposedAnswer.value = "";
        questionNumber.innerText = parseInt(questionNumber.innerText, 10) + 1;
        boxQuizQuestion.innerHTML = `<span>${quizQuestions[parseInt(questionNumber.innerText, 10) - 1].question}</span>`;
        elementId.value = quizQuestions[parseInt(questionNumber.innerText, 10) - 1].id;

        launchBoxPicture.removeChild(imgFirst);
        imgSecond.classList.add('img-first');
        imgSecond.classList.remove('img-second');
        boxQuizWithClues.classList.add('flipped');

        identifyMode();
        makeElementAppear(boxQuizForm);

        setTimeout(() => boxQuizWithClues.classList.add('flip'), 1);
        setTimeout(() => {
          boxQuizWithClues.classList.remove('flip');
          boxQuizWithClues.classList.remove('flipped');
        }, 400);
        setTimeout(() => launchBoxPicture.insertAdjacentHTML('beforeend', `<img height="200" width="250" class="img-second"
          src="https://res.cloudinary.com/dxtybzm3a/image/upload/f_auto,q_70,c_scale,dpr_auto,h_200,r_5,w_250/${quizQuestions[parseInt(questionNumber.innerText, 10)].image}"
          alt="${quizQuestions[parseInt(questionNumber.innerText, 10)].imageAlt}" title="${quizQuestions[parseInt(questionNumber.innerText, 10)].imageAlt}">`), 700);
    }

    if (parseInt(questionNumber.innerText, 10) === quizQuestions.length) {
      if (locale === "fr") { seeScore = "Voir Score "};
      btnNextQuestion.innerText = seeScore;
      btnNextQuestion.classList.remove('btn-success');
      btnNextQuestion.classList.add('btn-primary');
    }
    proposedAnswer.focus();
    setTimeout (() => {
      if (boxQuizForm.classList.contains('not-active')) { boxQuizForm.classList.remove('not-active') };
    }, 1000);
  };

  const determineClueUsed = () => {
    if (btnClueThree.classList.contains('clue-used')) {
      return "clue_three";
    } else if (btnClueTwo.classList.contains('clue-used')) {
      return "clue_two";
    } else if (btnClueOne.classList.contains('clue-used')) {
      return "clue_one";
    } else {
      return "success";
    }
  }

  const checkAnswerRemotely = (answer, element, clue) => {
    colorEyeBlack(btnClueOne, btnClueTwo, btnClueThree);
    fetchWithToken(`/quizzes/${quizId.value}/association_answer`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        element: {
          answer: answer,
          element_id: element,
          clue: clue,
          mode: quizMode
        }
      })
    })
      .then(response => response.json())
      .then((data) => {
        if (locale === "fr" ) {
          domageText = " Dommage ! ";
          notTheGoodAnswer = "<span>Ce n'est pas la bonne réponse ! </span>";
          wellDone = " Bien joué ! ";
          expectedAnswer = " est la réponse attendue !"
        };

        if (data.success === true) {
          proposedAnswer.value = "";
          displayResult(data, wellDone, 'fa-grin-alt', 'rgb(42, 157, 143)', data.complement, expectedAnswer);
          updateProgress(progressSuccessMain, progressSuccessNavbar, progressUnity);
        } else if (data.success === "fail") {
          proposedAnswer.value = "";
          displayResult(data, domageText, 'fa-frown', 'rgb(214, 64, 69)', data.complement, expectedAnswer);
          updateProgress(progressFailMain, progressFailNavbar, progressUnity);
        } else {
          boxQuizWithClues.classList.add('shaking');
          setTimeout(() => boxQuizWithClues.classList.remove('shaking'), 510);
          if (quizMode === 1) {
            if (windowWidth <= 500) {
              $('#clues-helper-wrapper').slideUp();
              setTimeout(() => {
                boxClues.querySelector('span').innerText = "";
                beginnerHelper.innerHTML = `${notTheGoodAnswer} <br> > ${data.helper} <`;
                $('#clues-helper-wrapper').slideDown();
              }, 330);
            } else {
              makeElementDisappear(cluesHelperWrapper);
              setTimeout(() => {
                boxClues.querySelector('span').innerText = "";
                beginnerHelper.innerHTML = `${notTheGoodAnswer} <br> > ${data.helper} <`;
                makeElementAppear(cluesHelperWrapper);
              }, 330);
            }
          }
          proposedAnswer.focus();
          if (quizMode === 4) {
            proposedAnswer.value = "";
            displayResult(data, domageText, 'fa-frown', 'red', data.complement, expectedAnswer);
            updateProgress(progressFailMain, progressFailNavbar, progressUnity);
          }
        }
      });
  }

  btnFail.addEventListener('click', (event) => {
    checkAnswerRemotely("fail", elementId.value, "fail");
  });

  btnClueOne.addEventListener('click', (event) => {
    if (!btnClueOne.classList.contains('clue-used')) {
      btnClueOne.classList.add('clue-used');
      setTimeout(() => btnClueTwo.classList.add('sliding-down-one'), 300);
    }
    colorEyeBlack(btnClueOne, btnClueTwo, btnClueThree);
    displayingClue(quizQuestions[parseInt(questionNumber.innerText, 10) - 1].clueOne, btnClueOne);
  });

  btnClueTwo.addEventListener('click', (event) => {
    colorEyeBlack(btnClueOne, btnClueTwo, btnClueThree);
    if (btnClueOne.classList.contains('clue-used')) {
      if (!btnClueTwo.classList.contains('clue-used')) {
        btnClueTwo.classList.add('clue-used');
        setTimeout(() => btnClueThree.classList.add('sliding-down-one'), 300);
      }
      displayingClue(quizQuestions[parseInt(questionNumber.innerText, 10) - 1].clueTwo, btnClueTwo);
    }
  });

  btnClueThree.addEventListener('click', (event) => {
    colorEyeBlack(btnClueOne, btnClueTwo, btnClueThree);
    if (btnClueTwo.classList.contains('clue-used')) {
      if (!btnClueThree.classList.contains('clue-used')) {
        btnClueThree.classList.add('clue-used');
      }
      displayingClue(quizQuestions[parseInt(questionNumber.innerText, 10) - 1].clueThree, btnClueThree);
    }
  });

  btnNextQuestion.addEventListener('click', (event) => {
    if (parseInt(questionNumber.innerText, 10) < quizQuestions.length) {
      event.preventDefault();
      setNextQuestion();
    }
  });


  window.addEventListener('keydown', (event) => {
    if (event.key === 'Enter' ) {
      if (parseInt(questionNumber.innerText, 10) < quizQuestions.length) {
        event.preventDefault();
        // if (!btnNextQuestion.classList.contains('not-active')) {
        if ((btnNextQuestion.classList.contains('ready-for-click')) && (!btnNextQuestion.classList.contains('not-active'))) {
          btnNextQuestion.classList.remove('ready-for-click');

          setNextQuestion();
        };
      }
    };
  });

  btnSubmitAnswer.addEventListener("click", (event) => {
    event.preventDefault();
    proposedAnswer.blur();
    const clueUsed = determineClueUsed();
    checkAnswerRemotely(proposedAnswer.value, elementId.value, clueUsed);
  });

  formAnswer.addEventListener("keydown", (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      proposedAnswer.blur();
      const clueUsed = determineClueUsed();

      checkAnswerRemotely(proposedAnswer.value, elementId.value, clueUsed);
    }
  });


}

export { associationAnswer };

