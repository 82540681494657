const footer = () => {

  $(document).ready(function(){
    (init())
  });
  $( window ).resize(function() {
    init()
  });

  function init() {
    var win = $('.footer');
    var windowWidth = win.width();

    if (locale === "fr") {
      if (windowWidth >= 0 && windowWidth <= 575) {
        document.querySelector(".footer").innerHTML = `
        <div class="footer-sm police-normal">
          <div class="footer-title auto-l-r-margin ">
            <a class="footer-link-title" href="/">Kuizy</a>
          </div>
          <a class="auto-l-r-margin" href="/">Accueil</a>
          <a class="auto-l-r-margin" href="/about">A propos</a>
          <a class="auto-l-r-margin" href="/categories">Catégories</a>
        </div>`
      } else {
        document.querySelector(".footer").innerHTML = `
        <div class="container footer-lg">
          <div class="row">
            <div class="footer-col col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <div class="footer-title">
                <a class="footer-link-title" href="/">Kuizy</a>
              </div>
              <a class="police-normal" href="/">Accueil</a>
              <a class="police-normal" href="/about">A propos</a>
              <a class="police-normal" href="/mentions_legales">Mentions Légales</a>
            </div>

            <div class="footer-col col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <div class="footer-title">
                <a class="footer-link-title" href="/categories">Catégories</a>
              </div>
              <a class="police-normal" href="/categories">Géographie</a>
              <a class="police-normal" href="/categories">Démographie</a>
              <a class="police-normal" href="/categories">Economie</a>
              <a class="police-normal" href="/categories">Cinéma et TV</a>
              <a class="police-normal" href="/categories">Sports</a>
            </div>

            <div class="footer-col col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <div class="footer-title">
                <span class="visually-hidden">.</span>
              </div>
            </div>
          </div>
        </div>`
      }
    } else {
      if (windowWidth >= 0 && windowWidth <= 575) {
        document.querySelector(".footer").innerHTML = `
        <div class="footer-sm police-normal">
          <div class="footer-title auto-l-r-margin ">
            <a class="footer-link-title" href="/">Kuizy</a>
          </div>
          <a class="auto-l-r-margin" href="/">Home</a>
          <a class="auto-l-r-margin" href="/about">About</a>
          <a class="auto-l-r-margin" href="/categories">Categories</a>
        </div>`
      } else {
        document.querySelector(".footer").innerHTML = `
        <div class="container footer-lg">
          <div class="row">
            <div class="footer-col col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <div class="footer-title">
                <a class="footer-link-title" href="/">Kuizy</a>
              </div>
              <a class="police-normal" href="/">Home</a>
              <a class="police-normal" href="/about">About</a>
              <a class="police-normal" href="/privacy_policy">Privacy Policy</a>
            </div>

            <div class="footer-col col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <div class="footer-title">
                <a class="footer-link-title" href="/categories">Catégories</a>
              </div>
              <a class="police-normal" href="/categories">Geography</a>
              <a class="police-normal" href="/categories">Demography</a>
              <a class="police-normal" href="/categories">Economy</a>
              <a class="police-normal" href="/categories">Cinema and TV</a>
              <a class="police-normal" href="/categories">Sports</a>
            </div>

            <div class="footer-col col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <div class="footer-title">
                <span class="visually-hidden">.</span>
              </div>
            </div>
          </div>
        </div>`
      }
    }
  }
};

export { footer };
