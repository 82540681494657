import { fetchWithToken } from "../utils/fetch_with_token";
import Sortable from "sortablejs";
import Swal from 'sweetalert2';
import { updateProgress } from "../utils/updateProgress";
import { makeElementAppear } from "../utils/appearAndDisappear";
import { makeElementDisappear } from "../utils/appearAndDisappear";

const rankingAnswer = () => {
  var win = $('.footer');
  var windowWidth = win.width();

  const questionNumbers = document.getElementById("question-numbers");
  const questionNumber = document.getElementById("question-number");
  const boxQuizWithClues = document.querySelector('.box-quiz-with-clues');
  const progressUnity = (100 / parseInt(questionNumbers.innerText, 10));

  const attemptHelperWrapper = document.getElementById('attempt-helper-wrapper');
  const attempt = document.querySelector(".attempt");
  const attemptNumber = document.getElementById("attempt-number");
  const borderHelper = document.getElementById('border-helper');
  const rankingHelper1 = document.querySelector('.ranking-helper-1');
  const rankingHelper2 = document.querySelector('.ranking-helper-2');
  const rankingHelper3 = document.querySelector('.ranking-helper-3');

  const collectionAnswerBox = document.getElementById('collection-answer-box');
  const boxQuizForm = document.getElementById('box-quiz-form');
  const rankingClues = document.querySelector('.ranking-clues');
  const rankingClueOne = document.getElementById('ranking-clue-one');
  const rankingClueTwo = document.getElementById('ranking-clue-two');
  const rankingClueThree = document.getElementById('ranking-clue-three');
  const btnScore = document.getElementById('btn-score');
  const btnScore2 = document.getElementById('btn-score-2');

  const list = document.getElementById('ranking-items');
  const btnSubmitList = document.getElementById('btn-submit-list');
  const quizId = document.getElementById("element_quiz_id");
  const btnAnswers = document.getElementById('btn-answers');
  let orderProposition = [];
  let orderPropositionClue = [];
  let numberOfAttemptsWithoutClue = 0;
  let found = 'no';

  let wellDone = "Well done !! ";
  let tooBad = " Too bad ! ";
  let wellDoneText = " You have properly ranked every item !";
  let tooBadText = " Below the expected order !";
  let sweetTitle = " Are you sure ? ";
  let sweetText = " Do you want to stop the quiz and see the answers ? ";
  let sweetYes = " Yes ";
  let sweetNo = " Cancel ";

  if (quizMode < 3) {
    setTimeout(() => makeElementAppear(rankingClueOne), 1000)
  }


  const displayBorderLeft = () => {
    list.querySelectorAll('.ranking-item').forEach((item, index) => {
        if ((!item.classList.contains('card-found')) && (orderProposition.length > 0)) {
            if (item.dataset.id === orderProposition[index].id) {
                if (!item.classList.contains('border-red')) { item.classList.add('border-red') };
            } else {
                if (item.classList.contains('border-red')) { item.classList.remove('border-red') };
            }
        }
    });
  }

  const displayIndex = () => {
    list.querySelectorAll('.ranking-item').forEach((item, index) => {
        if (!item.classList.contains('card-found')) {
            item.querySelector('.span-ranking-order').innerText = index + 1;
        }
    });
  };

  const sortable = Sortable.create(list, {
    swap: true,
    swapClass: 'blue-background-class',
    animation: 150,
    forceFallback: true,
    filter: ".disabled",
    onEnd: (event) => {
        if (numberOfAttemptsWithoutClue > 0) { displayBorderLeft() };
        displayIndex();
      },
    onMove: (event) => {
      return event.related.className.indexOf('disabled') === -1;
    }
  });

  const updateQuestionNumber = () => {
    questionNumber.innerText = parseInt(questionNumber.innerText, 10) + 1;
  }

  const updateCardFound = (card, item, border, progress) => {
    if (progress === 'success') {
      updateProgress(document.getElementById("progress-success-main"), document.getElementById("progress-success-navbar"), progressUnity);
      updateQuestionNumber();
    } else if (progress === 'fail') {
      updateProgress(document.getElementById("progress-fail-main"), document.getElementById("progress-fail-navbar"), progressUnity);
      updateQuestionNumber();
    }
    if (card.classList.contains('border-red')) { card.classList.remove('border-red') };
    card.classList.add('card-found');
    card.parentElement.classList.add('disabled');
    card.classList.add('flipped-up');
    setTimeout(() => {
      if (item.name.length > 0) {
        card.innerHTML = `
          <div class="ranking-part">
            <div class="ranking-order"><span class="span-ranking-order">${item.indexItem}</span></div>
            <div class="ranking-name"><span>${item.name}</span></div>
          </div>
          <div class="ranking-text police-smaller"><span>${item.value}</span></div>
        `;
      } else {
        card.querySelector('.ranking-order').innerHTML = `<span class="span-ranking-order">${item.indexItem}</span>`;
        card.querySelector('.ranking-text').innerHTML = `<span>${item.value}</span>`;
      }
      card.style.backgroundColor = 'white';
      card.classList.add(border);
    }, 250);
    setTimeout(() => card.classList.add('flip-up'), 1);
    setTimeout(() => {
      card.classList.remove('flip-up')
      card.classList.remove('flipped-up')
    }, 400);
  }

  const constructOrderProposed = () => {
    orderProposition = [];
    list.querySelectorAll('.ranking-item').forEach((item, index) => {
      found = 'no';
      if (item.classList.contains('card-found')) {found = 'yes'}
      orderProposition.push({indexItem: index + 1, id: item.dataset.id, found: found, name: "" })
    })
  }

  const constructOrderProposedClue = () => {
    orderPropositionClue = [];
    list.querySelectorAll('.ranking-item').forEach((item, index) => {
      found = 'no';
      if (item.classList.contains('card-found')) { found = 'yes' };
      orderPropositionClue.push({indexItem: index + 1, id: item.dataset.id, found: found, name: "" });
    })
  }

  const displayFinalMessage = (result) => {
    if (locale === "fr" ) {
      wellDone = " Bien joué ! ";
      tooBad = " Dommage ! ";
      wellDoneText = " Tu as réussi à tout classer dans le bon ordre !";
      tooBadText = " Ci-dessous l'ordre attendu !";
    };

    if (result === 'success') {
      collectionAnswerBox.innerHTML = `
        <div id="answer-symbol"><i class="fas fa-lg fa-grin-stars" style='color:rgb(42, 157, 143);'></i></div>
        <div>
          <span id='inject-text'>${wellDone}</span>
          <span id="answer-phrase">${wellDoneText}</span>
        </div>
      `;
    } else {
      collectionAnswerBox.innerHTML = `
        <div id="answer-symbol"><i class="fas fa-lg fa-frown" style='color:rgb(214, 64, 69);'></i></div>
        <div>
          <span id='inject-text'>${tooBad}</span>
          <span id="answer-phrase">${tooBadText}</span>
        </div>
      `;
    }
  }

  const riseAttemptNumber = () => {
    if (windowWidth <= 500) {
      setTimeout(() => attemptNumber.innerText = parseInt(attemptNumber.innerText, 10) + 1, 400);
    } else {
      setTimeout(() => attemptNumber.innerText = parseInt(attemptNumber.innerText, 10) + 1, 200);
    }
  }

  const setQuizEnd = (result) => {
    displayFinalMessage(result);

    if (windowWidth <= 500) {
        $('.launch-box-body').slideUp();
        makeElementDisappear(boxQuizForm);
        makeElementDisappear(btnAnswers);
        makeElementDisappear(attempt);
        makeElementDisappear(borderHelper);
        if (rankingClues) { makeElementDisappear(rankingClues) };

        setTimeout(() => {
          makeElementAppear(collectionAnswerBox);
          makeElementAppear(btnScore);
          makeElementAppear(btnScore.querySelector('a'));
          makeElementAppear(btnScore2);
          makeElementAppear(btnScore2.querySelector('a'))
          $('.launch-box-body').slideDown();
        }, 500);

    } else {
        makeElementDisappear(boxQuizForm);
        makeElementDisappear(btnAnswers);
        makeElementDisappear(attempt);
        makeElementDisappear(borderHelper);
        if (rankingClueOne) { makeElementDisappear(rankingClueOne) };
        if (rankingClueTwo) { makeElementDisappear(rankingClueTwo) };
        if (rankingClueThree) { makeElementDisappear(rankingClueThree) };
        setTimeout(() => {
          makeElementAppear(collectionAnswerBox);
          makeElementAppear(btnScore);
          makeElementAppear(btnScore.querySelector('a'));
          makeElementAppear(btnScore2);
          makeElementAppear(btnScore2.querySelector('a'))
        }, 500);
    }
  }

  const displayHelperText = (clueNumber) => {
    if (windowWidth <= 500) {
        $('#attempt-helper-wrapper').slideUp();
        if (clueNumber === 'one') {
            makeElementAppear(borderHelper);
            makeElementAppear(rankingHelper1);
        } else if (clueNumber === 'two') {
            makeElementDisappear(rankingHelper1);
            makeElementAppear(rankingHelper2);
        } else if (clueNumber === 'three') {
            makeElementDisappear(rankingHelper2);
            makeElementAppear(rankingHelper3);
        }
        setTimeout(() => $('#attempt-helper-wrapper').slideDown(), 500);
    } else {
        makeElementDisappear(attempt);
        if (clueNumber === 'one') {
            setTimeout(() => makeElementAppear(rankingHelper1), 500);
        } else if (clueNumber === 'two') {
            makeElementDisappear(rankingHelper1);
            makeElementDisappear(borderHelper);
            setTimeout(() => makeElementAppear(rankingHelper2), 500);
        } else if (clueNumber === 'three') {
            makeElementDisappear(rankingHelper2);
            makeElementDisappear(borderHelper);
            setTimeout(() => makeElementAppear(rankingHelper3), 500);
        }
        setTimeout(() => {
          makeElementAppear(attempt);
          makeElementAppear(borderHelper);
        }, 500);
    }

  }

  const analyseAnswer = (data) => {
    if (data.success === 'success') {
        data.answer.forEach(item => {
            const cardToUpdate = document.getElementById(`ranking-card-id-${item.id}`);
            if (!cardToUpdate.classList.contains('card-found')) { updateCardFound(cardToUpdate, item, 'border-green', 'success') };
            cardToUpdate.style.borderRight = '';
        });
        setQuizEnd('success');
    } else {
        data.answer.forEach(item => {
            const cardToUpdate = document.getElementById(`ranking-card-id-${item.id}`);
            if (item.success === "success") {
                if (!cardToUpdate.classList.contains('card-found')) { updateCardFound(cardToUpdate, item, 'border-green', 'success') };
                cardToUpdate.style.borderRight = '';
            } else {
                if (!cardToUpdate.classList.contains('card-found')) { cardToUpdate.classList.add('border-red') };
              // cardToUpdate.style.borderRight = item.border_right;
            }
        });
    }
  }

  const colorBorderRight = (data) => {
    data.answer.forEach(item => {
      const card = document.getElementById(`ranking-card-id-${item.id}`);
      if (!card.classList.contains('card-found')) { card.style.borderRight = item.border_right };
    });
  }

  const checkPropositionRemotly = () => {
    constructOrderProposed();
    fetchWithToken(`/quizzes/${quizId.value}/ranking_answer`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        element: {
          orderProposed: orderProposition,
          mode: quizMode,
          number: parseInt(questionNumbers.innerText, 10)
        }
      })
    })
      .then(response => response.json())
      .then((data) => {
        riseAttemptNumber();
        numberOfAttemptsWithoutClue = numberOfAttemptsWithoutClue + 1;
        analyseAnswer(data);
      });
  }

  const revealAnswers = () => {
    constructOrderProposed();
    fetchWithToken(`/quizzes/${quizId.value}/ranking_fail`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        element: {
          orderProposed: orderProposition,
          mode: quizMode
        }
      })
    })
      .then(response => response.json())
      .then((data) => {
        if (data.success === 'giveup') {
          list.querySelectorAll('.ranking-item').forEach((card, index) => {
            if (data.answer[index].success === 'success') {
              updateCardFound(card, data.answer[index], 'border-green', 'giveup');
            } else {
              updateCardFound(card, data.answer[index], 'border-red', 'fail');
            }
            card.style.borderRight = '';
          })
          setQuizEnd('fail');
        }
      });
  }

  const fetchClue = (clueNumber) => {
    constructOrderProposedClue();
    fetchWithToken(`/quizzes/${quizId.value}/ranking_clue`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        element: {
          orderProposed: orderPropositionClue,
          mode: quizMode,
          number: parseInt(questionNumbers.innerText, 10),
          clueNumber: clueNumber
        }
      })
    })
      .then(response => response.json())
      .then((data) => {
        riseAttemptNumber();
        setTimeout(() => colorBorderRight(data), 300);
        displayHelperText(clueNumber);
      })
  };

  const removeBorderHelper = () => {
    list.querySelectorAll('.ranking-item').forEach((item, index) => {
      item.style.borderRight = 'none';
    });
  }

  btnSubmitList.addEventListener('click', (event) => {
    event.preventDefault();
    checkPropositionRemotly();
  });

  if (quizMode < 3) {
    rankingClueOne.addEventListener('click', (event) => {
      fetchClue('one');
      makeElementDisappear(rankingClueOne);
      setTimeout(() => {
        rankingClueTwo.classList.add('eye-clue-two');
        makeElementAppear(rankingClueTwo);
      }, 500);
    });

    rankingClueTwo.addEventListener('click', (event) => {
      removeBorderHelper();
      fetchClue('two');
      makeElementDisappear(rankingClueTwo);
      setTimeout(() => {
        rankingClueTwo.classList.remove('eye-clue-two');
        makeElementAppear(rankingClueThree);
      }, 500);
    });

    rankingClueThree.addEventListener('click', (event) => {
      removeBorderHelper();
      fetchClue('three');
      makeElementDisappear(rankingClueThree);
    });
  }

  btnAnswers.addEventListener('click', (event) => {
    event.preventDefault();
    if (locale === "fr" ) {
      sweetTitle = " Es-tu sûr ? ";
      sweetText = " Arrêter le quiz et voir les réponses ? ";
      sweetYes = " Oui ";
      sweetNo = " Annuler ";
    };
    Swal.fire({
      title: sweetTitle,
      text: sweetText,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: sweetNo,
      confirmButtonText: sweetYes
    }).then((result) => {
      if (result.value) {
        revealAnswers();
      }
    })
  });
}

export { rankingAnswer };
