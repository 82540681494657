const sliderCards = () => {

  var scaling = 1;
  //count
  var win = $('.container');
  var windowWidth = win.width();
  var frameWidth = win.width() - 80;
  if(windowWidth >= 0 && windowWidth <= 414){
    var showCount = 2;
  } else if (windowWidth >= 414 &&  windowWidth <= 768) {
    var showCount = 3;
  } else {
    var showCount = 4;
  }

  // var showCount = 4;
  var controlsWidth = 40;

  var currentSliderCountGeography = 0;
  var videoCountGeography = $(".slider-container-geography").children().length;
  var sliderCountGeography = videoCountGeography / showCount;
  var scollWidthGeography = 0;

  var currentSliderCountSports = 0;
  var videoCountSports = $(".slider-container-sports").children().length;
  var sliderCountSports = videoCountSports / showCount;
  var scollWidthSports = 0;

  var currentSliderCountDemography = 0;
  var videoCountDemography = $(".slider-container-demography").children().length;
  var sliderCountDemography = videoCountDemography / showCount;
  var scollWidthDemography = 0;

  var currentSliderCountCinema = 0;
  var videoCountCinema = $(".slider-container-cinema").children().length;
  var sliderCountCinema = videoCountCinema / showCount;
  var scollWidthCinema = 0;

  var currentSliderCountEconomy = 0;
  var videoCountEconomy = $(".slider-container-economy").children().length;
  var sliderCountEconomy = videoCountEconomy / showCount;
  var scollWidthEconomy = 0;


  $(document).ready(function(){
    //$('.slider-container .slide:nth-last-child(-n+4)').prependTo('.slider-container');
    init('geography', videoCountGeography, currentSliderCountGeography, scollWidthGeography, sliderCountGeography);
    init('sports', videoCountSports, currentSliderCountSports, scollWidthSports, sliderCountSports);
    init('demography', videoCountDemography, currentSliderCountDemography, scollWidthDemography, sliderCountDemography);
    init('cinema', videoCountCinema, currentSliderCountCinema, scollWidthCinema, sliderCountCinema);
    init('economy', videoCountEconomy, currentSliderCountEconomy, scollWidthEconomy, sliderCountEconomy);
  });
  $( window ).resize(function() {
    init('geography', videoCountGeography, currentSliderCountGeography, scollWidthGeography, sliderCountGeography);
    init('sports', videoCountSports, currentSliderCountSports, scollWidthSports, sliderCountSports);
    init('demography', videoCountDemography, currentSliderCountDemography, scollWidthDemography, sliderCountDemography);
    init('cinema', videoCountCinema, currentSliderCountCinema, scollWidthCinema, sliderCountCinema);
    init('economy', videoCountEconomy, currentSliderCountEconomy, scollWidthEconomy, sliderCountEconomy);
  });
  function init(category, videoCount, currentSliderCount, scollWidth, sliderCount){
    // elements
    var win = $('.container');
    var sliderFrame = $(`.slider-frame-${category}`);
    var sliderContainer = $(`.slider-container-${category}`);
    var slide = $(`.slide-${category}`);

    //counts
    var scollWidth = 0;


    //sizes
    var windowWidth = win.width();
    var frameWidth = win.width() - 80;
    if(windowWidth >= 0 && windowWidth <= 414) {
      showCount = 2;
    } else if (windowWidth >= 414 &&  windowWidth <= 768) {
      showCount = 3;
    } else {
      showCount = 4;
    }
    var videoWidth = ((windowWidth - controlsWidth * 2) / showCount );
    var videoHeight = Math.round(videoWidth / (16/9));

    var videoWidthDiff = (videoWidth * scaling) - videoWidth;
    var videoHeightDiff = (videoHeight * scaling) - videoHeight;



    //set sizes
    sliderFrame.width(windowWidth);
    sliderFrame.height(videoHeight * scaling);


    //sliderFrame.css("top", (videoHeightDiff / 2));

    sliderContainer.height(videoHeight * scaling);
    sliderContainer.width((videoWidth * videoCount) + videoWidthDiff);
    sliderContainer.css("top", (videoHeightDiff / 2));
    // sliderContainer.css("margin-left", (controlsWidth));

    slide.height(videoHeight);
    slide.width(videoWidth);

    //hover effect
    // $(".slide").mouseover(function() {
    //     $(this).css("width", videoWidth * scaling);
    //     $(this).css("height", videoHeight * scaling);
    //     $(this).css("top", -(videoHeightDiff / 2));
    //     if($(".slide").index($(this)) == 0 || ($(".slide").index($(this))) % 4 == 0){
    //       // do nothing
    //     }
    //     else if(($(".slide").index($(this)) + 1) % 4 == 0 && $(".slide").index($(this)) != 0){
    //         $(this).parent().css("margin-left", -(videoWidthDiff - controlsWidth));
    //     }
    //     else{
    //         $(this).parent().css("margin-left", - (videoWidthDiff / 2));
    //     }
    // }).mouseout(function() {
    //     $(this).css("width", videoWidth * 1);
    //     $(this).css("height", videoHeight * 1);
    //     $(this).css("top", 0);
    //     $(this).parent().css("margin-left", controlsWidth);
    // });

    // controls
    controls(frameWidth, scollWidth, category, currentSliderCount, sliderCount);
  }
  function controls(frameWidth, scollWidth, category, currentSliderCount, sliderCount){
    var prev = $(`.prev-${category}`);
    var next = $(`.next-${category}`);

    next.on("click", function(){
      scollWidth = scollWidth + frameWidth;
      $(`.slider-container-${category}`).animate({
          left: - scollWidth
      }, 300, function(){
          if(currentSliderCount >= sliderCount-1) {
              $(`.slider-container-${category}`).css("left", 0);
              currentSliderCount = 0;
              scollWidth = 0;
          } else {
              currentSliderCount++;
          }
      });
    });
    prev.on("click", function(){
      scollWidth = scollWidth - frameWidth;
      $(`.slider-container-${category}`).animate({
          left: + scollWidth
      }, 300, function(){
          currentSliderCount--;
      });
      //$(".slider-container").css("left", scollWidth);
    });
  };
}

export { sliderCards };
