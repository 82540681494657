// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports
import "bootstrap";

// Internal imports, e.g:
// import { loadDynamicBannerText } from '../components/banner';
import { associationAnswer } from '../quizzes/associationAnswer';
import { collectionAnswer } from '../quizzes/collectionAnswer';
import { rankingAnswer } from '../quizzes/rankingAnswer';
import { displayScore } from '../quizzes/displayScore';
import { scoreChart } from '../components/scoreChart';
import { sliderCards } from '../categories/sliderCards';
import { footer } from '../components/footer';
import { navbar } from '../components/navbar';
import { quizHeaderMain } from '../components/quizHeaderMain';

// import { initSelect2 } from '../components/init_select2';

document.addEventListener('turbolinks:load', () => {
  // loadDynamicBannerText();
  if (document.getElementById("association-quiz")) { associationAnswer() };
  if (document.getElementById("collection-quiz")) { collectionAnswer() };
  if (document.getElementById("ranking-quiz")) { rankingAnswer() };
  if (document.getElementById("display-score")) { displayScore() };
  if (document.getElementById("score-chart")) { scoreChart() };
  if (document.getElementById("slider-cards")) { sliderCards() };
  if (document.getElementById("quiz-header-main")) { quizHeaderMain() };

  footer();
  navbar();


  // Call your functions here, e.g:
  // initSelect2();


});
