const quizHeaderMain = () => {

  $(document).ready(function(){
    (init())
  });
  $( window ).resize(function() {
    init()
  });

  function init() {
    var win = $('.footer');
    var windowWidth = win.width();
    const quizHeaderMain = document.getElementById('quiz-header-main');


    if (windowWidth <= 500) {
      quizHeaderMain.style.display = "none";
    } else {
      quizHeaderMain.style.display = "flex";
    }

  }
};

export { quizHeaderMain };
