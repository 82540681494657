const makeElementAppear = (element) => {
  element.classList.remove('not-active');
  setTimeout(() => element.classList.remove('visually-hidden'), 20);
}

const makeElementDisappear = (element) => {
  element.classList.add('visually-hidden');
  setTimeout(() => element.classList.add('not-active'), 310);
}

export { makeElementAppear, makeElementDisappear };
