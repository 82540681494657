import { fetchWithToken } from "../utils/fetch_with_token";
import { updateProgress } from "../utils/updateProgress";
import { makeElementAppear } from "../utils/appearAndDisappear";
import { makeElementDisappear } from "../utils/appearAndDisappear";
import { colorEyeBlack } from "../utils/colorEyeBlack";

const collectionAnswer = () => {
  var win = $('.footer');
  var windowWidth = win.width();

  const questionNumbers = document.getElementById("question-numbers");
  const questionNumber = document.getElementById("question-number");
  const boxQuizWithClues = document.querySelector('.box-quiz-with-clues');
  const collectionMainWrapper = document.getElementById('collection-main-wrapper');
  const progressUnity = (100 / parseInt(questionNumbers.innerText, 10));
  const beginnerHelper = document.getElementById('beginner-helper');
  const collectionAnswerBox = document.getElementById('collection-answer-box');

  const btnScore = document.getElementById('btn-score');
  const btnScore2 = document.getElementById('btn-score-2');
  const boxQuizForm = document.getElementById('box-quiz-form');
  const formAnswer = document.getElementById("form-answer");
  const quizId = document.getElementById("element_quiz_id");
  const proposedAnswer = document.getElementById("proposed-answer");
  const btnSubmitAnswer = document.getElementById('btn-submit-answer');
  const btnClueOneS = document.querySelectorAll('.collection-btn-clue-one');
  const btnClueTwoS = document.querySelectorAll('.collection-btn-clue-two');
  const btnClueThreeS = document.querySelectorAll('.collection-btn-clue-three');
  const btnFail = document.getElementById('btn-fail');
  let answers = [];

  let notTheGoodAnswer = "<span>It is not the right answer ! </span>";
  let wellDone = "Well done ! ";
  let alreadyFound = " has already been found !";
  let foundAll = " You have found all the answers !";
  let expectedAnswer = " is one of the expected answers !";
  let domageText = " Too bad ! ";
  let answersNotFound = " Below the answers not found ! ";

  const slideLeftTransition = (element) => {
    setTimeout(() => {
      element.classList.remove('width-transition-hidden');
      element.style.maxWidth = "none";
      let width = element.offsetWidth;
      element.style.maxWidth = "0px";
      setTimeout(() => element.style.maxWidth = `${width}px`, 1);
      setTimeout(() => element.style.maxWidth, 10);
      element.style.marginRight = '10px';
    }, 1030);
  }

  const clearAnswerBox = () => {
    makeElementDisappear(collectionAnswerBox);
    collectionAnswerBox.innerHTML = "";
  }

  const displayAnswerBox = (symbol, color, text, answer, phrase, complement) => {
    collectionAnswerBox.innerHTML = `
      <div id="answer-symbol">
        <i class="fas fa-lg ${symbol}" style='color:${color};'></i>
      </div>
      <div>
        <span id='inject-text'>${text}</span>
        <strong><span id='inject-answer' style='color:${color};'>${answer.charAt(0).toUpperCase() + answer.slice(1)}</span></strong>
        <span  id="answer-phrase">${phrase}</span><span id='rescue-answer'>${complement}</span>
      </div>`;
    makeElementAppear(collectionAnswerBox);
    proposedAnswer.value = "";
  }

  const updateCollectionCard = (cardToUpdate, answer, symbol, color) => {
    cardToUpdate.classList.add('flipped-up');
    setTimeout(() => {
      cardToUpdate.innerHTML = `
        <div class="collection-answer police-normal"><span>${answer}</span></div>
        <div class="collection-check"><i class="fas fa-lg fa-${symbol}" style='color:${color};'></i></div>`
      cardToUpdate.style.backgroundColor = 'white';
      cardToUpdate.classList.add('card-found');
    }, 250);
    setTimeout(() => cardToUpdate.classList.add('flip-up'), 1);
    setTimeout(() => {
      cardToUpdate.classList.remove('flip-up')
      cardToUpdate.classList.remove('flipped-up')
    }, 400);
  }

  const hideAndShowClues = (elementId, clueText, btn) => {
    const wrapper = `#collection-wrapper-id-${elementId}`;
    const wrapperText = document.getElementById(`clue-text-id-${elementId}`);
    if (!$(wrapper).hasClass('clue-hidden')) {
      if (wrapperText.innerText === clueText) {
        $(wrapper).slideUp();
        $(wrapper).addClass('clue-hidden');
        setTimeout(() => { wrapperText = "" }, 500);
      } else {
        $(wrapper).slideUp();
        $(wrapper).addClass('clue-hidden');
        setTimeout(() => { wrapperText.innerText = clueText }, 520);
        btn.querySelectorAll('.far').forEach((element) => element.style.color = 'rgb(119, 182, 234)');
        setTimeout(() => {
          $(wrapper).slideDown();
          $(wrapper).removeClass('clue-hidden');
        }, 580);
      }
    } else {
      $(wrapper).slideUp();
      setTimeout(() => { wrapperText.innerText = clueText }, 520);
      setTimeout(() => {
        $(wrapper).slideDown();
        $(wrapper).removeClass('clue-hidden');
      }, 580);
      btn.querySelectorAll('.far').forEach((element) => element.style.color = 'rgb(119, 182, 234)');

    }
  }

  const updateQuizQuestions = (idOfFoundElement) => {
    quizQuestions.forEach(function (element, index) {
      if (element.id === idOfFoundElement) { quizQuestions.splice(index, 1) }
    })
  }

  const endQuiz = (symbol, color, text1, text2, text3, text4) => {
    if (windowWidth <= 500) {
        $('.launch-box-body').slideUp();
        setTimeout(() => {
          makeElementDisappear(boxQuizForm);
          makeElementDisappear(document.querySelector('.collection-btn-fail'));
          displayAnswerBox(symbol, color, text1, text2, text3, text4);
          makeElementAppear(btnScore);
          makeElementAppear(btnScore.querySelector('a'));
          makeElementAppear(btnScore2);
        }, 250)
        setTimeout(() => $('.launch-box-body').slideDown(), 600);
    } else {
        setTimeout(() => displayAnswerBox(symbol, color, text1, text2, text3, text4), 500);
        makeElementDisappear(boxQuizForm);
        makeElementDisappear(document.querySelector('.collection-btn-fail'));
        setTimeout(() => makeElementAppear(btnScore), 500);
        setTimeout(() => makeElementAppear(btnScore.querySelector('a')), 500);
        setTimeout(() => makeElementAppear(btnScore2), 500);
    }
  }

  const reverseCard = (dataId, dataAnswer, progress1, symbol, color, progress2) => {
    questionNumber.innerText = parseInt(questionNumber.innerText, 10) + 1;
    updateProgress(document.getElementById(progress1), document.getElementById(progress2), progressUnity);
    const wrapper = document.getElementById(`collection-wrapper-id-${dataId}`);
    $(`#collection-wrapper-id-${dataId}`).slideUp();
    $(`#collection-wrapper-id-${dataId}`).addClass('clue-hidden');
    updateCollectionCard(document.getElementById(`collection-card-id-${dataId}`), dataAnswer, symbol, color);
  }

  const checkAnswerRemotely = () => {
    if (quizMode === 1) {
      if (windowWidth <= 500) {
        $('#beginner-helper').slideUp();

      } else {
        makeElementDisappear(beginnerHelper);
      }
      setTimeout(() => beginnerHelper.innerHTML = "", 300);
    };

    fetchWithToken(`/quizzes/${quizId.value}/collection_answer`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        element: {
          answer: proposedAnswer.value,
          elements: quizQuestions,
          answers_found: answers,
          mode: quizMode
        }
      })
    })
      .then(response => response.json())
      .then((data) => {
        clearAnswerBox();
        if (locale === "fr" ) {
          notTheGoodAnswer = "<span>Ce n'est pas la bonne réponse ! </span>";
          wellDone = "Bien joué ! ";
          alreadyFound = " a déjà été trouvé !";
          foundAll = " Tu as tout trouvé !";
          expectedAnswer = " est une des réponses attendues !";
        };

        if (data.success === "already_found") {
          setTimeout(() => displayAnswerBox('fa-surprise', 'rgb(119, 182, 234)', "", data.answer, alreadyFound, ""), 340);
        } else if (data.success === true) {
          answers.push(data.answer);
          reverseCard(data.id, data.answer, 'progress-success-main', 'check', 'rgb(42, 157, 143)', 'progress-success-navbar');

          if (answers.length === parseInt(questionNumbers.innerText, 10)) {
            endQuiz('fa-grin-stars', 'rgb(42, 157, 143)', wellDone, "", foundAll, "");
          } else {
            setTimeout(() => displayAnswerBox('fa-grin-alt', 'rgb(42, 157, 143)', wellDone, data.answer, expectedAnswer, data.complement), 340);
          }
          updateQuizQuestions(data.id)
        } else {
          collectionMainWrapper.classList.add('shaking');
          setTimeout(() => collectionMainWrapper.classList.remove('shaking'), 510);
          if (quizMode === 1) {
            if (windowWidth <= 500) {
              setTimeout(() => {
                beginnerHelper.innerHTML = `${notTheGoodAnswer} <br> -> ${data.helper} <-`;
                $('#beginner-helper').slideDown()
              }, 400);
            } else {
              setTimeout(() => {
                beginnerHelper.innerHTML = `${notTheGoodAnswer} <br> -> ${data.helper} <-`;
                makeElementAppear(beginnerHelper)
              }, 400);
            }
          };
        }
      });
  }


  btnClueOneS.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const elementId = btn.parentElement.parentElement.dataset.id;
      const lookForIndex = (element) => element.id === parseInt(elementId, 10);
      const index = quizQuestions.findIndex(lookForIndex);
      colorEyeBlack(btn, btn.nextElementSibling, btn.nextElementSibling.nextElementSibling);
      hideAndShowClues(elementId, quizQuestions[index].clueOne, btn);
      if (quizQuestions[index].clueUsed === 'success') {
        quizQuestions[index].clueUsed = 'clue_one';
        slideLeftTransition(btn.nextElementSibling);
      };
    });
  });

  btnClueTwoS.forEach(function (btn, index) {
    btn.addEventListener('click', (event) => {
      const elementId = btn.parentElement.parentElement.dataset.id;
      const lookForIndex = (element) => element.id === parseInt(elementId, 10);
      const index = quizQuestions.findIndex(lookForIndex);
      colorEyeBlack(btn.previousElementSibling, btn, btn.nextElementSibling);
      hideAndShowClues(elementId, quizQuestions[index].clueTwo, btn);
      if (quizQuestions[index].clueUsed === 'clue_one') {
        quizQuestions[index].clueUsed = 'clue_two';
        setTimeout(() => slideLeftTransition(btn.nextElementSibling), 430);
      };
    });
  });

  btnClueThreeS.forEach(function (btn, index) {
    btn.addEventListener('click', (event) => {
      const elementId = btn.parentElement.parentElement.dataset.id;
      const lookForIndex = (element) => element.id === parseInt(elementId, 10);
      const index = quizQuestions.findIndex(lookForIndex);
      colorEyeBlack(btn.previousElementSibling.previousElementSibling, btn.previousElementSibling, btn);
      hideAndShowClues(elementId, quizQuestions[index].clueThree, btn);
      if (quizQuestions[index].clueUsed === 'clue_two') { quizQuestions[index].clueUsed = 'clue_three' };
    });
  });

  btnFail.addEventListener('click', (event) => {
    event.preventDefault();
    const cluesNotFound = [];
    document.querySelectorAll('.collection-card').forEach((card) => {
      if (!card.classList.contains('card-found')) { cluesNotFound.push(card.dataset.id) };
    });
    fetchWithToken(`/quizzes/${quizId.value}/collection_fail`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        element: {
          elements: cluesNotFound
        }
      })
    })
      .then(response => response.json())
      .then((data) => {
        if (locale === "fr" ) {
          domageText = " Dommage ! ";
          answersNotFound = " Ci-dessous les réponses non trouvées !";
        };

        clearAnswerBox();
        endQuiz('fa-frown', 'rgb(214, 64, 69)', domageText, "", answersNotFound, "");

        data.answer.forEach((answer, index) => {
          setTimeout(() => {
            reverseCard(answer.element_id, answer.answer, 'progress-fail-main', 'times', 'rgb(214, 64, 69)', 'progress-fail-navbar');
          }, 750 * (index + 1));
        })
      });
  });

  btnSubmitAnswer.addEventListener("click", (event) => {
    event.preventDefault();
    checkAnswerRemotely();
  });

  formAnswer.addEventListener("keydown", (event) => {
    if (event.key === 'Enter' ) {
      event.preventDefault();
      checkAnswerRemotely();
    }
  });

}

export { collectionAnswer };
