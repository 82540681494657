const navbar = () => {

  $(document).ready(function(){
    (init())
  });
  $( window ).resize(function() {
    init();
  });

  function init() {
    var win = $('#kuizy-navbar');
    var windowWidth = win.width() + 32;
    const navbarToggler = document.getElementById('navbarTogglerDemo03');
    const buttonNavbar = document.getElementById('button-navbar');
    const username = document.getElementById('username');
    const usernameWrapper = document.getElementById('username-wrapper');
    const quizHeaderNavbar = document.getElementById('quiz-header-navbar');

    if (windowWidth <= 991) {
      if (!navbarToggler.classList.contains('collapse-kuizy')) { navbarToggler.classList.add('collapse-kuizy') };
      if (username) {
        username.innerText = UsernameFull
        if (usernameWrapper.classList.contains('navbar-circle')) {
          usernameWrapper.classList.add('nav-link');
          usernameWrapper.classList.add('navbar-padding-left-none');
          usernameWrapper.classList.remove('navbar-circle');
        };
      };
    } else {
      if (navbarToggler.classList.contains('collapse-kuizy')) { navbarToggler.classList.remove('collapse-kuizy') };
      if (username) {
        username.innerText = UsernameFirstLetter;
        if (!usernameWrapper.classList.contains('navbar-circle')) {
        usernameWrapper.classList.remove('nav-link');
        usernameWrapper.classList.remove('navbar-padding-left-none');
        usernameWrapper.classList.add('navbar-circle');
        };
      };
    }

    if (windowWidth <= 500) {
      if (quizHeaderNavbar) {quizHeaderNavbar.style.display = "flex"};
    } else {
      if (quizHeaderNavbar) {quizHeaderNavbar.style.display = "none"};
    }

  }
};

export { navbar };
